@mixin font-family($font-family: 'Plus Jakarta Sans') {
  $font-stack: $font-family, sans-serif;
  font-family: $font-stack;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  label,
  span::not(:has(.material-symbols-rounded)),
  small,
  strong,
  button::not(:has(.btn)),
  input,
  input::placeholder,
  ol,
  li {
    $font-stack: $font-family, sans-serif;
    font-family: $font-stack !important;
  }
}
