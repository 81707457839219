#root {
  min-height: 100vh;
}

.container {
  height: 100% !important;
}

.container {
  flex-direction: column !important;
  display: flex !important;
}

.main-content {
  display: flex;
}

.main-block {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0;
}

.modal-advanced-options .padding-input-div-captcha {
  padding-right: 1.95rem;
}
.modal-advanced-options .modal-padding-top {
  padding-top: 32px;
}
.modal-advanced-options label p {
  color: #000;
  margin-bottom: 0;
}

/* Editor */
.ql-editor .ql-size-small {
  font-size: 0.75em;
}
.ql-editor .ql-size-large {
  font-size: 1.5em;
}
.ql-editor .ql-size-huge {
  font-size: 2.5em;
}

/* Modal - updates what comes from monolith */
.modal .modal-dialog .modal-content .modal-body {
  align-items: unset;
}
