.blkd-page {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.background-transparent {
  background-color: transparent;
}
