@import '../../styles/common/mixin';

.pageError {
  @include font-family;

  &:first-child {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
  }

  img {
    width: 264px;
  }

  h1 {
    font-weight: 800;
    font-size: 2rem;
    line-height: 120%;
    color: #454c5f;
    padding-top: 2rem;
  }

  p {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 150%;
    color: #000000;
  }

  button {
    background: #2396fb;
    border-color: #2396fb;
    border-radius: 0.25rem;
    font-weight: 600;
    font-size: 1rem;
  }
}
