@import '../../../../styles/common/_variables.scss';

.dropdown-wrapper {
  position: relative;

  .select-overlay {
    position: fixed;
    inset: 0;
    z-index: 1;
  }
  .wrapper-select {
    position: relative;
    z-index: 0;

    .select-dropdown {
      display: block;
      width: 100%;
      padding: 0.5rem 0.75rem;
      -moz-padding-start: calc(0.75rem - 3px);
      font-size: 1rem;
      font-weight: 400;
      color: #212529;
      border: 1px solid #ced4da;
      border-radius: 0.5rem;
      background-color: #fff;
      cursor: pointer;

      &:disabled,
      &[disabled] {
        cursor: auto !important;
      }
    }

    span {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.25rem;
      pointer-events: none;
    }
  }

  .dropdown-select {
    list-style: none;
    margin: 0;
    margin-top: 0.5rem;
    padding: 1rem;
    border: 1px solid $gray-16;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    background: #fff;
    width: 100%;

    &.focus {
      z-index: 2;
      position: absolute;
    }

    li {
      color: #000;
      padding: 0.5rem;
      transition: 0.2s;
      cursor: pointer;
      display: flex;
      align-items: center;

      input {
        width: 1rem;
        height: 1rem;
        cursor: pointer;
        margin-right: 0.5rem;
      }

      &.selected,
      &:hover {
        background: $primary-8;
      }
    }
  }
}
