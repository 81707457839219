@keyframes fade-in {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes entrance-from-top {
  0% {
    top: -10%;
  }
  100% {
    top: 0%;
  }
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-25deg);
  }
  50% {
    transform: rotate(25deg);
  }
  70% {
    transform: rotate(-25deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes background-animation-2 {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 200%;
  }
}
@keyframes background-animation-3 {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 300%;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(195, 227, 254, 1);
    box-shadow: 0 0 0 0 rgba(195, 227, 254, 1);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(195, 227, 254, 0);
    box-shadow: 0 0 0 10px rgba(195, 227, 254, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(195, 227, 254, 0);
    box-shadow: 0 0 0 0 rgba(195, 227, 254, 0);
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    -webkit-transform: scale(1);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    -webkit-transform: scale(1.4);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    -webkit-transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@-webkit-keyframes cliprotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes cliprotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  100% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  100% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}

@keyframes rotate-start {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes showfixed {
  0% {
    opacity: 0;
  }
  49.9% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes present-rotate {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@-webkit-keyframes present-rotate {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(360deg);
  }
}
@-webkit-keyframes lid-animation {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0);
  }
  5% {
    transform: translate3d(0, -10px, -5px) rotateX(5deg);
  }
  10% {
    transform: translate3d(0, -10px, 5px) rotateX(-5deg);
  }
  15% {
    transform: translate3d(0, -10px, -5px) rotateX(5deg);
  }
  20% {
    transform: translate3d(0, -10px, 5px) rotateX(-5deg);
  }
  25% {
    transform: translate3d(0, -10px, -5px) rotateX(5deg);
  }
  30% {
    transform: translate3d(0, 0, 0) rotateX(0);
  }
}
@keyframes lid-animation {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0);
  }
  5% {
    transform: translate3d(0, -10px, -5px) rotateX(5deg);
  }
  10% {
    transform: translate3d(0, -10px, 5px) rotateX(-5deg);
  }
  15% {
    transform: translate3d(0, -10px, -5px) rotateX(5deg);
  }
  20% {
    transform: translate3d(0, -10px, 5px) rotateX(-5deg);
  }
  25% {
    transform: translate3d(0, -10px, -5px) rotateX(5deg);
  }
  30% {
    transform: translate3d(0, 0, 0) rotateX(0);
  }
}

@-webkit-keyframes lid-top-right-animation {
  0% {
    transform: translate3d(52%, -99%, 0px) rotateY(90deg) rotateX(90deg);
  }
  5% {
    transform: translate3d(52%, -99%, 0px) rotateY(90deg) rotateX(60deg);
  }
  10% {
    transform: translate3d(52%, -99%, 0px) rotateY(90deg) rotateX(90deg);
  }
  15% {
    transform: translate3d(52%, -99%, 0px) rotateY(90deg) rotateX(60deg);
  }
  20% {
    transform: translate3d(52%, -99%, 0px) rotateY(90deg) rotateX(90deg);
  }
  25% {
    transform: translate3d(52%, -99%, 0px) rotateY(90deg) rotateX(60deg);
  }
  30% {
    transform: translate3d(52%, -99%, 0px) rotateY(90deg) rotateX(90deg);
  }
}
@keyframes lid-top-right-animation {
  0% {
    transform: translate3d(52%, -99%, 0px) rotateY(90deg) rotateX(90deg);
  }
  5% {
    transform: translate3d(52%, -99%, 0px) rotateY(90deg) rotateX(60deg);
  }
  10% {
    transform: translate3d(52%, -99%, 0px) rotateY(90deg) rotateX(90deg);
  }
  15% {
    transform: translate3d(52%, -99%, 0px) rotateY(90deg) rotateX(60deg);
  }
  20% {
    transform: translate3d(52%, -99%, 0px) rotateY(90deg) rotateX(90deg);
  }
  25% {
    transform: translate3d(52%, -99%, 0px) rotateY(90deg) rotateX(60deg);
  }
  30% {
    transform: translate3d(52%, -99%, 0px) rotateY(90deg) rotateX(90deg);
  }
}

@-webkit-keyframes lid-top-left-animation {
  0% {
    transform: translate3d(-48%, -99%, 0px) rotateY(90deg) rotateX(270deg);
  }
  5% {
    transform: translate3d(-48%, -99%, 0px) rotateY(90deg) rotateX(300deg);
  }
  10% {
    transform: translate3d(-48%, -99%, 0px) rotateY(90deg) rotateX(270deg);
  }
  15% {
    transform: translate3d(-48%, -99%, 0px) rotateY(90deg) rotateX(300deg);
  }
  20% {
    transform: translate3d(-48%, -99%, 0px) rotateY(90deg) rotateX(270deg);
  }
  25% {
    transform: translate3d(-48%, -99%, 0px) rotateY(90deg) rotateX(300deg);
  }
  30% {
    transform: translate3d(-48%, -99%, 0px) rotateY(90deg) rotateX(270deg);
  }
}
@keyframes lid-top-left-animation {
  5% {
    transform: translate3d(-48%, -99%, 0px) rotateY(90deg) rotateX(270deg);
  }
  10% {
    transform: translate3d(-48%, -99%, 0px) rotateY(90deg) rotateX(300deg);
  }
  15% {
    transform: translate3d(-48%, -99%, 0px) rotateY(90deg) rotateX(270deg);
  }
  20% {
    transform: translate3d(-48%, -99%, 0px) rotateY(90deg) rotateX(300deg);
  }
  25% {
    transform: translate3d(-48%, -99%, 0px) rotateY(90deg) rotateX(270deg);
  }
  30% {
    transform: translate3d(-48%, -99%, 0px) rotateY(90deg) rotateX(300deg);
  }
  35% {
    transform: translate3d(-48%, -99%, 0px) rotateY(90deg) rotateX(270deg);
  }
}

@-webkit-keyframes carrousel {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@keyframes carrousel {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@media (max-width: 1500px) {
  @-webkit-keyframes carrousel {
    0% {
      left: 0;
    }
    100% {
      left: -1500px;
    }
  }
  @keyframes carrousel {
    0% {
      left: 0;
    }
    100% {
      left: -1500px;
    }
  }
}

@-webkit-keyframes scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

@-webkit-keyframes shake {
  0% {
    transform: rotate(0);
  }
  5% {
    transform: rotate(5deg);
  }
  10% {
    transform: rotate(-5deg);
  }
  15% {
    transform: rotate(5deg);
  }
  20% {
    transform: rotate(-5deg);
  }
  25% {
    transform: rotate(5deg);
  }
  30% {
    transform: rotate(0);
  }
}
@keyframes shake {
  0% {
    transform: rotate(0);
  }
  5% {
    transform: rotate(5deg);
  }
  10% {
    transform: rotate(-5deg);
  }
  15% {
    transform: rotate(5deg);
  }
  20% {
    transform: rotate(-5deg);
  }
  25% {
    transform: rotate(5deg);
  }
  30% {
    transform: rotate(0);
  }
}

@-webkit-keyframes bounce {
  0% {
    margin-top: 0px;
  }
  10% {
    margin-top: -25px;
  }
  20% {
    margin-top: 0;
  }
  45% {
    margin-top: -25px;
  }
  60% {
    margin-top: 0;
  }
  85% {
    margin-top: -25px;
  }
  100% {
    margin-top: 0px;
  }
}
@keyframes bounce {
  0% {
    margin-top: 0px;
  }
  16% {
    margin-top: -25px;
  }
  32% {
    margin-top: 0;
  }
  48% {
    margin-top: -25px;
  }
  62% {
    margin-top: 0;
  }
  78% {
    margin-top: -25px;
  }
  100% {
    margin-top: 0px;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0 !important;
  }
  50% {
    opacity: 0 !important;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes loading {
  0% {
    width: 0;
  }
  100% {
    width: 97%;
  }
}
@-webkit-keyframes roll-in-top {
  0% {
    -webkit-transform: translateY(-800px) rotate(-540deg);
    transform: translateY(-800px) rotate(-540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotate(0deg);
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
}
@keyframes roll-in-top {
  0% {
    -webkit-transform: translateY(-800px) rotate(-540deg);
    transform: translateY(-800px) rotate(-540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotate(0deg);
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
}

@-webkit-keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0) scale(1, 0.5);
    transform: translateY(0) scale(1, 0.5);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px) scale(1, 0.5);
    transform: translateY(-65px) scale(1, 0.5);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0) scale(1, 0.75);
    transform: translateY(0) scale(1, 0.75);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px) scale(1, 1);
    transform: translateY(-28px) scale(1, 1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0) scale(1, 0.85);
    transform: translateY(0) scale(1, 0.85);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px) scale(1, 1);
    transform: translateY(-8px) scale(1, 1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  36% {
    -webkit-transform: translateY(0) scale(1, 1);
    transform: translateY(0) scale(1, 1);
  }
  38% {
    -webkit-transform: translateY(0) scale(1, 0.75);
    transform: translateY(0) scale(1, 0.75);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px) scale(1, 1);
    transform: translateY(-65px) scale(1, 1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  70% {
    -webkit-transform: translateY(0) scale(1, 1);
    transform: translateY(0) scale(1, 1);
  }
  72% {
    -webkit-transform: translateY(0) scale(1, 0.95);
    transform: translateY(0) scale(1, 0.95);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px) scale(1, 1);
    transform: translateY(-28px) scale(1, 1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0) scale(1, 1);
    transform: translateY(0) scale(1, 1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px) scale(1, 1);
    transform: translateY(-8px) scale(1, 1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@-webkit-keyframes scale-in-bottom {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-bottom {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 1;
  }
}

@-webkit-keyframes rotate-in-center {
  0% {
    -webkit-transform: rotate(-360deg) scale(0);
    transform: rotate(-360deg) scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
    opacity: 1;
  }
}
@keyframes rotate-in-center {
  0% {
    -webkit-transform: rotate(-360deg) scale(0);
    transform: rotate(-360deg) scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
    opacity: 1;
  }
}
