.loadingSpinner {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;

  .spinner {
    position: relative;
    width: 4.5rem;
    height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before,
    &::after {
      border: 0.53rem solid #a8dffa;
      border-radius: 50%;
      position: absolute;
      content: '';
      display: block;
    }

    &::before {
      width: 2.7rem;
      height: 2.7rem;
      border-bottom-color: transparent;
      border-left-color: transparent;
      animation: spinnerAnimation 1.05s infinite linear reverse;
    }

    &::after {
      animation: spinnerAnimation 0.7s infinite linear;
      height: 4.5rem;
      width: 4.5rem;
      border-right-color: transparent;
      border-top-color: transparent;
    }
  }

  @keyframes spinnerAnimation {
    to {
      transform: rotate(360deg);
    }
  }
}
