@import '../../../styles/common/_variables.scss';
@import '../../../styles/common/animation.scss';

.modal {
  position: absolute;
  width: 100%;
  border: 1px solid black;
  background-color: #fff;
  z-index: 901;
  animation: fade-in 0.5s ease;
  border-radius: 16px;
  box-shadow: $shadow-5;
  top: 0;
  transition: 0.5s;

  &.small {
    max-width: 360px;
  }
  &.medium {
    max-width: 570px;
  }
  &.big {
    max-width: 800px;
  }
  &.full {
    max-width: calc(100% - 2rem);
  }

  header {
    padding: 1rem;
    border-radius: 16px 16px 0 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray-8;

    title {
      font-size: 1rem;
      font-weight: 800;
      display: block;
    }
  }

  main {
    padding: 1rem;
    border-bottom: 1px solid $gray-8;
    display: flex;
    flex-direction: column;

    label {
      font-size: 0.85rem;
      font-weight: 500;
      color: #000;
    }

    .main-title {
      font-size: 0.85rem;
      font-weight: 300;
    }
  }

  footer {
    padding: 1rem;
    background-color: #f6fbff;
    border-radius: 0 0 1rem 1rem;
  }

  &.fade-in {
    animation: fade-in 0.5s ease;
    visibility: visible;
    top: 3.5rem;
  }

  &.fade-out {
    animation: fade-out 0.5s ease;
    visibility: hidden;
  }
}
