@import '../../styles/common/_variables.scss';
@import '../../styles/common/_mixin.scss';

.wrapper-modal {
  width: 100%;
  height: 3rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 991;
  box-shadow: $shadow-5;
  animation: entrance-from-top 1s ease;
  @include font-family;
}

.wrapper-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > span {
    margin-bottom: 1rem;
  }
}

.wrapper-title {
  height: 1.5rem;
  font-size: 1rem;
  font-weight: 800;
}

.badge {
  font-size: 0.75rem;
  font-weight: 400;
  background-color: #fff8e6;
  border: 1px solid #ffae0b;
  color: #ffae0b;
  padding: 0.25rem 0.75rem;
  margin-left: 0.5rem;
  border-radius: 50px;
  text-transform: capitalize;
}

.draft-modal {
  main {
    button,
    label {
      margin-top: 1.5rem;
    }
    .fillForm {
      font-size: 0.85rem;
      font-weight: 500;
      color: #000;
      margin-top: 1.5rem;
    }
  }
}
