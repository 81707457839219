@import '../../../../styles/common/_variables.scss';

.icon-button {
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;

  span:last-child {
    font-size: 0.65rem;
    font-weight: 600;
  }

  span:first-child {
    font-size: 1rem;
  }

  span {
    color: $gray-space;
  }

  &:hover {
    background: transparent !important;
  }
}
