@import '../../../../styles/common/_variables.scss';

.button {
  border-radius: 50px;
  background: transparent;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 0;
  min-width: 75px;
  width: fit-content;
  justify-content: center;

  &.lg {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
  }
  &.md {
    font-size: 0.85rem;
    padding: 0.65rem 1rem;
  }
  &.sm {
    font-size: 0.75rem;
    padding: 0.5rem 0.75rem;
  }

  &.text-dark {
    color: $gray-space !important;
  }

  .loading {
    width: 1rem;
    height: 1rem;
    border: 0.25rem solid;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-radius: 50%;
    animation: loading 1s linear infinite;
    opacity: 1;
    position: absolute;
  }

  .content {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    transition: 0.5s width;
    white-space: nowrap;
  }

  &.primary {
    background-color: $primary-pure;
    color: $white;

    &:hover {
      color: $white;
      background: $primary-hover;
    }

    span {
      color: $white;
    }

    &:disabled {
      background: $gray-16 !important;
      color: $gray-32 !important;

      span {
        color: $gray-32 !important;
      }
    }
  }

  &.secondary {
    border: 1px solid $primary-24;
    color: $primary-pure;

    &:hover {
      color: $primary-hover;
      border-color: $primary-hover;
      border: 1px solid $primary-hover;
      background: $primary-8;
    }

    span {
      color: $primary-hover;
    }

    &:disabled {
      background: $gray-8;
      color: $gray-32;

      span {
        color: $gray-32 !important;
      }
    }
  }

  &.tertiary {
    color: $primary-pure;
    padding-left: 0 !important;
    padding-right: 0 !important;

    &:disabled {
      color: $gray-32;

      span {
        color: $gray-32 !important;
      }
    }
    &:hover {
      background: inherit;
    }
  }

  @keyframes loading {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
